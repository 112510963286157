import React from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import "./App.css";
import Chat from "./components/Chat";
import Home from './components/Home.js';
import CjRoom from './components/createJoinRoom';
import {Setup} from "./components/Settings";
import {auth} from "./firebase";
import {Switch, BrowserRouter as Router, Route} from "react-router-dom";

function App() {
  const [user] = useAuthState(auth);
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/cjRoom">
            <CjRoom />
          </Route>
          <Route exact path="/chat/:roomId">
            {user ? <Chat /> : <Home />}
          </Route>
          <Route exact path="/chat">
            {user ? <Chat /> : <Home />}
          </Route>
          <Route exact path="/setup">
            {user ? <Setup /> : <Home />}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
