import React, {useState, useEffect, useRef} from "react";
import {Settings} from "./Settings";
import firebase from "firebase/compat/app";
import {db, auth} from "../firebase";
import {useHistory, Link, useLocation} from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Checkbox from '@material-ui/core/Checkbox';
import SupervisedUserCircleIcon  from '@material-ui/icons/SupervisedUserCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import "./Sidebar.css";
import "./ChatArea.css";
import "./todo.css";
import TextField from "@material-ui/core/TextField";
import {useAuthState} from 'react-firebase-hooks/auth';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import ClearIcon from '@material-ui/icons/Clear';
import Zoom from '@material-ui/core/Zoom';
import "./popupwindow.css";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
import Filter from "bad-words";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

function Chat() {
    let location = useLocation();
    let history = useHistory();
    const [user] = useAuthState(auth);
    db.collection("users").doc(firebase.auth().currentUser.uid).get().then((doc)=> {
        try{
            if(db.collection("users").doc(firebase.auth().currentUser.uid).get() !== null || db.collection("users").doc(firebase.auth().currentUser.uid).get().exists){
                if(doc.data().fName === "" || doc.data().lName === ""){
                    history.push('/setup');
                }
            }
        }catch(e){
            history.push('/setup');
        }
    });
    if(user === null){
        history.push("/");
    }
    const [fName, setFname] = useState("");
    const [lName, setLname] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [chats, setChats] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [settingsChatName, setSettingsChatName] = useState("");
    const [task, setTask] = useState([]);
    const [urlData, setUrlData] = useState("");
    const [deleteChatCode, setDeleteChatCode] = useState("");
    const [chatSettingSwearing, setChatSettingSwearing] = useState(urlData.profanity);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [adminInviteLink, setAdminInviteLink] = useState("");
    const [memberInviteLink, setMemberInviteLink] = useState("")
    const [adminUserOfChatId, setAdminUserOfChatId] = useState([]);
    const [membersInChatId, setMembersInChatId] = useState([]);
    const [popupChatSetting, setPopupChatSetting] = useState("hidePopup");
    const [tableInformation, setTableInformation] = useState([]);
    const scrollToThis = useRef();
    let chatsTempVar = [];
    useEffect(()=>{
        setTasks();
    },[])
    

    useEffect(()=>{
       chatSetterFunc();
       
       return () => {
           setChats(
            {}
           );
       }
    },[]);

    useEffect(()=>{
        if(deleteChatCode === urlData.ChatName){
            document.getElementById("deleteChatButton").classList.add("canDelete");
            document.getElementById("deleteChatButton").classList.remove("notDelete");
        }else{
            document.getElementById("deleteChatButton").classList.add("notDelete");
            document.getElementById("deleteChatButton").classList.remove("canDelete");
        }
    }, [deleteChatCode]);

    
    
    
    function chatSetterFunc(){
        db.collection("chats").onSnapshot((snapshot)=>{
            chatsTempVar = [];
            snapshot.docs.map((doc)=>{
                for(let i=0; i<doc.data().members.length; i++){
                    if(doc.data().members[i]===auth.currentUser.uid){
                        chatsTempVar.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                }
            })
            setChats(chatsTempVar)
        });
    }


    useEffect(()=>{
        db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("messages").orderBy("dateSent").limitToLast(100).onSnapshot(snapshot => {
            try{
                setMessages(snapshot.docs.map(doc => doc.data()))
                scrollToThis.current.scrollIntoView(
                {
                    behavior:'smooth',
                    block: "nearest",
                    inline: "start"
                })
            }catch(err){
                console.log(err);
            }
        });

        db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("tasks").orderBy("datePosted").onSnapshot(snapshot => {
            try{
                setTasks(
                    snapshot.docs.map(doc => 
                        doc.data()
                    ))
            }catch(err){
                console.log(err);
            }
        });
        db.collection("chats").doc((location.pathname).replace("/chat/", "")).get().then((doc)=>{
            if(location.pathname.replace("/chat/", "") !== "/chat"){
                setAdminUserOfChatId(
                    doc.data().admin
                );
            }
        })
        

    },[location.pathname]);
    document.body.onkeyup = function(e){
        if(e.keyCode == 32){
            let updatedTableInformation = []
            membersInChatId.map((id)=>{
                db.collection("users").doc(id).onSnapshot((doc)=>{
                    updatedTableInformation.push(
                        {
                            fName: doc.data().fName,
                            lName: doc.data().lName,
                            email: doc.data().mail
                        }
                    );
                    setTableInformation(updatedTableInformation)
                });
            })
        }
    }
    useEffect(()=>{
        let updatedTableInformation = []
        membersInChatId.map((id)=>{
            db.collection("users").doc(id).onSnapshot((doc)=>{
                updatedTableInformation.push(
                    {
                        key: id,
                        fName: doc.data().fName,
                        lName: doc.data().lName,
                        email: doc.data().mail
                    }
                );
                setTableInformation(updatedTableInformation)
            });
        })
    }, [membersInChatId])
    useEffect(()=>{
      db.collection("chats").doc((location.pathname).replace("/chat/", "")).get().then((docSnapshot)=>{
        if(docSnapshot.exists){
          db.collection("chats").doc((location.pathname).replace("/chat/", ""))
          .onSnapshot((doc)=>{
            setUrlData(doc.data());
            setChatSettingSwearing(doc.data().profanity);
            setMembersInChatId(doc.data().members);
          })
          
        }
      })
    },[location.pathname, ]);


    function signOutOfAccount()
    {
        auth.signOut();
        history.push('/');
    }
    async function deleteTask(taskId){
        await db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("tasks").doc(taskId).delete();
    }

    setProfileInformation();
    async function setProfileInformation(){
        const currentUserFromFirebase = firebase.auth().currentUser;
        await db.collection("users").doc(currentUserFromFirebase.uid).get().then((doc)=> {
            if(doc.data()){
                setFname(doc.data().fName);
                setLname(doc.data().lName.charAt(0)+".");
                setImgUrl(doc.data().imgUrl);
            }else{
                setProfileInformation();
            }
        });
    }
    function joinCreateRoom()
    {
        history.push("/cjRoom");
    }
    toast.configure();
    async function popupSetProfanity(e){
        e.preventDefault();
        const docRef = db.collection("chats").doc((location.pathname).replace("/chat/", ""));
        if(chatSettingSwearing === undefined){
            setChatSettingSwearing(false);
            docRef.update({profanity: chatSettingSwearing})
            toast.success('Success', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        }else{
            docRef.update({profanity: chatSettingSwearing})
            toast.success('Success', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        }
    }
    async function popupSetChatName(e){
        e.preventDefault();
        if(settingsChatName.length <= 2){
            toast.warning('Chat Name must be more than 2 letters long.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            if(settingsChatName.length > 15){
                toast.warning('Chat Name must be less than 16 letters long.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                const docRef = db.collection("chats").doc((location.pathname).replace("/chat/", ""));
                docRef.update({ChatName: settingsChatName})
                toast.success('Success', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                chatSetterFunc();
                setChats(chatsTempVar);
                setSettingsChatName("");
            }
        }
    }

    async function sendMessage(e)
    {
        e.preventDefault();
        if(urlData.profanity === false){
            await db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("messages").add({
                dateSent: firebase.firestore.FieldValue.serverTimestamp(),
                message: message,
                composerUid: auth.currentUser.uid,
                imageUrl: imgUrl,
                name: `${fName} ${lName}`
            },{merge:true}).then(
                setMessage(""),
                scrollToThis.current.scrollIntoView({behavior:'smooth',
                block: "nearest",
                inline: "start"
                })
            );
        }
        else{
            const filter = new Filter()
            if(filter.isProfane(message) && auth.currentUser.uid !== urlData.admin){
                const cleaned = filter.clean(message);

                
                let updatedMembers = [];
                db.collection("chats").doc((location.pathname).replace("/chat/", "")).get().then((doc)=>{
                    if(location.pathname.replace("/chat/", "") !== "/chat"){
                        doc.data().members.map((userId)=>{
                            if(userId !== auth.currentUser.uid){
                                updatedMembers.push(userId);
                            }
                        })
                    }
                }).then(()=>{
                    banUser(updatedMembers, cleaned);
                })
            }else{
                await db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("messages").add({
                    dateSent: firebase.firestore.FieldValue.serverTimestamp(),
                    message: message,
                    composerUid: auth.currentUser.uid,
                    imageUrl: imgUrl,
                    name: `${fName} ${lName}`
                },{merge:true}).then(
                    setMessage(""),
                    scrollToThis.current.scrollIntoView({behavior:'smooth',
                    block: "nearest",
                    inline: "start"
                    })
                );
            }
            
        }
    }

    async function banUser(updatedMembers, cleaned){
        toast.error(`You Have Been Removed from the chat for saying ${cleaned}.`, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        await db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("messages").add({
            dateSent: firebase.firestore.FieldValue.serverTimestamp(),
            message: `${fName} ${lName} was removed from the server for saying ${cleaned}`,
            composerUid: auth.currentUser.uid,
            imageUrl: imgUrl,
            name: `${fName} ${lName}`
        },{merge:true}).then(
            await db.collection("chats").doc((location.pathname).replace("/chat/", "")).update({
                members: updatedMembers
            }).then(chatSetterFunc()),
            chatSetterFunc(),
            setMessage(""),
            scrollToThis.current.scrollIntoView({behavior:'smooth',
            block: "nearest",
            inline: "start"
            })
        );
        history.push("/chat");
    }

    async function addTask(e)
    {
        e.preventDefault();
        await db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("tasks").add({
            datePosted: firebase.firestore.FieldValue.serverTimestamp(),
            task: task,
            id:"",
        },{merge:true}).then(
            function(docRef) {
                console.log(docRef.id);
                db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("tasks").doc(docRef.id).update({id: String(docRef.id) })
                
                      
                                
                // In the line above set id to the docref.id we jsut need to set it.
            },
            setTask(""),
            // scrollToThis.current.scrollIntoView({behavior:'smooth',
            // block: "nearest",
            // inline: "start"
            // })
        );
    }
    function setChatSettingSwearingFunction(e)
    {
        setChatSettingSwearing(e.target.checked);
    }
    async function generateAdminInviteLinkFunction(e, gr){
        e.preventDefault();
        if(gr === "g"){
            let genLink=random();
            await db.collection("chats").doc((location.pathname).replace("/chat/", ""))
                .set({
                    adminInviteLink: genLink
                }, {merge: true})
                setAdminInviteLink(genLink)
                toast.success('Successfully Created Invite Code', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        }else{
            let genLink="";
            await db.collection("chats").doc((location.pathname).replace("/chat/", ""))
                .set({
                    adminInviteLink: genLink
                }, {merge: true})
                setAdminInviteLink(genLink)
                toast.success('Successfully Removed Invite Code', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        }
    }       
    async function generateMemberInviteLinkFunction(e, gr){
        e.preventDefault();
        if(gr === "g"){
            let genLink=random();
            await db.collection("chats").doc((location.pathname).replace("/chat/", ""))
                .set({
                    memberInviteLink: genLink
                }, {merge: true})
                setMemberInviteLink(genLink)
                toast.success('Successfully Created Invite Code', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        }else{
            let genLink="";
            await db.collection("chats").doc((location.pathname).replace("/chat/", ""))
                .set({
                    memberInviteLink: genLink
                }, {merge: true})
                setMemberInviteLink(genLink)
                toast.success('Successfully Removed Invite Code', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        }
    }
    async function deleteChat(e){
        e.preventDefault();
        if(deleteChatCode === urlData.ChatName){
            await db.collection("chats").doc((location.pathname).replace("/chat/", "")).delete();
            history.push("/chat");
            window.location.reload();
        }
    }
    async function leaveChat(){
        let updatedMembers = [];
                db.collection("chats").doc((location.pathname).replace("/chat/", "")).get().then((doc)=>{
                    if(location.pathname.replace("/chat/", "") !== "/chat"){
                        doc.data().members.map((userId)=>{
                            if(userId !== auth.currentUser.uid){
                                updatedMembers.push(userId);
                            }
                        })
                    }
        }).then(()=>{
            toast.success(`You Have Left the chat`, {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            db.collection("chats").doc((location.pathname).replace("/chat/", "")).collection("messages").add({
                dateSent: firebase.firestore.FieldValue.serverTimestamp(),
                message: `${fName} ${lName} has left the chat`,
                composerUid: auth.currentUser.uid,
                imageUrl: imgUrl,
                name: `${fName} ${lName}`
            },{merge:true}).then(
                db.collection("chats").doc((location.pathname).replace("/chat/", "")).update({
                    members: updatedMembers
                }).then(chatSetterFunc()),
                chatSetterFunc(),
                setMessage(""),
                scrollToThis.current.scrollIntoView({behavior:'smooth',
                block: "nearest",
                inline: "start"
                })
            );
            history.push("/chat");            
        })
    }

    const random = (length = 8) => {
        // Declare all characters
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
        // Pick characers randomly
        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    
        return str;
    };

    function sidebarRightArrow(){
        document.getElementById("sidebarID").classList.add("hideSidebarMobile");
        document.getElementById("sidebarID").classList.remove("showSidebarMobile");
        document.getElementById("chat-areaID").classList.add("showChatAreaMobile");
        document.getElementById("chat-areaID").classList.remove("hideChatAreaMobile");
    }
    function chatLeftArrow(){
        document.getElementById("sidebarID").classList.remove("hideSidebarMobile");
        document.getElementById("sidebarID").classList.add("showSidebarMobile");
        document.getElementById("chat-areaID").classList.remove("showChatAreaMobile");
        document.getElementById("chat-areaID").classList.add("hideChatAreaMobile");
    }
    function chatRightArrow(){
        document.getElementById("sidebarID").classList.add("hideSidebarMobile");
        document.getElementById("sidebarID").classList.remove("showSidebarMobile");
        document.getElementById("chat-areaID").classList.remove("showChatAreaMobile");
        document.getElementById("chat-areaID").classList.add("hideChatAreaMobile");
        document.getElementById("todo-areaID").classList.remove("hideTodoAreaMobile");
        document.getElementById("todo-areaID").classList.add("showTodoAreaMobile");
    }
    function todoLeftArrow(){
        document.getElementById("sidebarID").classList.add("hideSidebarMobile");
        document.getElementById("sidebarID").classList.remove("showSidebarMobile");
        document.getElementById("chat-areaID").classList.add("showChatAreaMobile");
        document.getElementById("chat-areaID").classList.remove("hideChatAreaMobile");
        document.getElementById("todo-areaID").classList.add("hideTodoAreaMobile");
        document.getElementById("todo-areaID").classList.remove("showTodoAreaMobile");
    }
    return (
    <div className="chat-app">

        <div className={popupChatSetting}>
            <div id="settingsPopup">
                <div onClick={()=>{setPopupChatSetting("hidePopup")}} className="darkBG" />
                <div className="container">
                    <div className="background">
                        <ClearIcon className="exit" onClick={()=>{setPopupChatSetting("hidePopup")}} />
                        <div className="container">
                            <h1>{urlData.ChatName ? urlData.ChatName : "____"}'s Settings</h1>
                            <div className="sub-container">
                                    <form>
                                        <h2>Set Chat Name - </h2>
                                        <TextField className="inputField textField" value={settingsChatName} required id="standard-basic" label="Chat Name" onChange={(e)=>{setSettingsChatName(e.target.value)}}  />
                                        <button type="submit" onClick={(e)=>popupSetChatName(e)}>
                                            Set
                                        </button>
                                    </form>
                                    <form>
                                        <h2>Prevent Swearing - </h2>
                                        <Checkbox 
                                            className="inputField"
                                            checked={chatSettingSwearing ? true : false}
                                            onChange={setChatSettingSwearingFunction}
                                        />
                                        <button type="submit" onClick={(e)=>popupSetProfanity(e)}>
                                            Set
                                        </button>
                                    </form>
                                    <form>
                                        <h2>Invite Admin - {adminInviteLink}</h2>
                                        <button type="submit" onClick={(e)=>generateAdminInviteLinkFunction(e, "g")}>
                                            Generate
                                        </button>
                                        <button type="submit" onClick={(e)=>generateAdminInviteLinkFunction(e, "r")}>
                                            Remove
                                        </button>
                                    </form>
                                    <form>
                                        <h2>Invite Member - {memberInviteLink}</h2>
                                        <button type="submit" onClick={(e)=>generateMemberInviteLinkFunction(e, "g")}>
                                            Generate
                                        </button>
                                        <button type="submit" onClick={(e)=>generateMemberInviteLinkFunction(e, "r")}>
                                            Remove
                                        </button>
                                    </form>
                                    <form>
                                        <h2>Delete Chat - </h2>
                                        <TextField className="inputField textField" value={deleteChatCode} required id="standard-basic" label="Chat Name" onChange={(e)=>{setDeleteChatCode(e.target.value)}}  />
                                        <button type="submit" className="" id="deleteChatButton" onClick={(e)=>deleteChat(e)}>
                                            Delete
                                        </button>
                                    </form>
                
                                    <div className="chatMembers">
                                        <h2>Members</h2>
                                        <table>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email Adress</th>
                                            </tr>
                                            {tableInformation.map((userInfo, index)=>{
                                                return(
                                                    <tr key={tableInformation[index].key}>
                                                        <td>{tableInformation[index].fName}</td>
                                                        <td>{tableInformation[index].lName}</td>
                                                        <td>{tableInformation[index].email}</td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
          
        <div className="sidebar" id="sidebarID">
                <div className="info">
                    {(imgUrl === "") ? <AccountCircleIcon style={{fontSize:50}} /> : <img src={imgUrl} className="imgUrl" />}
                    <h1 className="importantTitle">{fName} {lName}</h1>
                    <div className="rightArrowContainer" onClick={()=>{sidebarRightArrow()}}>
                        {((((location.pathname).replace("/chat/", "")) !== "/chat")) ?
                        <ArrowRightAltIcon className="rightArrow" />
                        :
                        <div />
                        }
                    </div>
                </div>
                {/* Chat List */} 
                <div className="chatList">
                    <h2>Chat List:</h2>       
                    {/* Chat List Below */}



                    {chats.map((chat)=>{
                        return(
                            <Link style={{textDecoration:"none"}} to={`/chat/${chat.id}`}> 
                                <div key={chat.id} className="chatRoomOption">
                                    <SupervisedUserCircleIcon style={{fontSize:30}} />
                                    <h3 className="importantTitle s-leftPadding">
                                        {chat.data.ChatName ? chat.data.ChatName : "____"}
                                    </h3>
                                </div> 
                            </Link>
                        );
                    })}


                </div>
                {/* Settings/Create Room & Join Room */}
                <div className="bottomOptions">
                    <div className="options" onClick={joinCreateRoom}>
                        <AddBoxIcon style={{fontSize:30}} />
                        <h2 className="importantTitle s-leftPadding">Create/Join</h2>
                    </div>
                    <div className="options settings">
                        <SettingsIcon style={{fontSize:30}} />
                        <h2 className="importantTitle s-leftPadding">Settings</h2>
                    </div>
                    <div className="signOut" onClick={signOutOfAccount}>
                        <ExitToAppIcon style={{fontSize:30}} />
                        <h2 className="importantTitle s-leftPadding">Sign Out</h2>
                    </div>
                </div>
            </div>
            {(urlData&&((location.pathname).replace("/chat/", "")) !== "/chat") ?
            <div> 
                {/* ChatArea */}
                <div className="chat-area" id="chat-areaID">
                    <div className="chat-header">
                        <div className="arrowsContainer">
                            <div className="leftArrowContainer" onClick={()=>{chatLeftArrow()}}>
                                <ArrowRightAltIcon className="leftArrow" />
                            </div>
                            <div className="rightArrowContainer" onClick={()=>{chatRightArrow()}}>
                                <ArrowRightAltIcon className="rightArrow" />
                            </div>
                        </div>
                        <div className="chatInfoContainer">
                            <div>
                                <SupervisedUserCircleIcon style={{fontSize:60}}/>
                                <h1>{urlData.ChatName ? urlData.ChatName : "_______"}</h1>
                            </div>
                            {(adminUserOfChatId.includes(auth.currentUser.uid)) ? 
                                <SettingsIcon onClick={()=>{setPopupChatSetting("showPopup")}} className="setting" style={{fontSize:30}} />
                                : 
                                <div>
                                    <ExitToAppIcon onClick={()=>{leaveChat()}} className="LeaveChat" style={{fontSize:30}} />
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="messages">
                        {messages.map(({composerUid,message,name,imageUrl, dateSent})=>{
                            let composedBy="";
                            if(composerUid === auth.currentUser.uid){
                                composedBy="composedByMe";
                            }
                            else{
                                composedBy="composedBySomeoneElse";
                            }
                            return(
                                <Zoom  in={true} mountOnEnter unmountOnExit>
                                    <div className={`${composedBy}`}>
                                        <div className="messageImgText">
                                            <img src={imageUrl} />
                                            <h1>{message}</h1>
                                        </div>
                                        <h6>{name} - {new Date(dateSent?.toDate()).toUTCString()}</h6>
                                    </div>
                                </Zoom>
                            );
                        })}
                        <div style={{height: "00px"}} ref={scrollToThis} />
                    </div>
                    <div className="send">
                        <form onSubmit={sendMessage}>
                            <input className="input" placeholder="Message..." value={message} onChange={(e)=>{setMessage(e.target.value)}} type="text"/>
                            <button className="submit" type="submit">
                                <SendRoundedIcon />
                            </button>
                        </form>
                    </div>
                </div>
            
            </div>
            : 
            <div className="clickAchatToStartMessagingContainer">
                <h1 className="clickAchatToStartMessaging">Click a chat to start messaging!</h1>
            </div>
            }
            {urlData ?
            <div>
                {/* TODO */}
                <div className="todo-area" id="todo-areaID">
                    <div className="leftArrowContainer" onClick={()=>{todoLeftArrow()}}>
                        {((((location.pathname).replace("/chat/", "")) !== "/chat")) ?
                        <ArrowRightAltIcon className="leftArrow" />
                        :
                        <div />
                        }
                    </div>

                    <div className="chat-header">
                        <h1>To Do:</h1>
                    </div>
                    <div className="taskContainer">


                    {tasks.map(({task, id})=>{
                            return(
                                <Zoom key={task.id}  in={true} mountOnEnter unmountOnExit>
                                    <div className="task">
                                        {(adminUserOfChatId.includes(auth.currentUser.uid)) ?
                                            <ClearIcon className="deleteBtn" onClick={()=>{deleteTask(id)}} />
                                        :
                                            <div>

                                            </div>
                                        }  
                                        <h3>{task}</h3>
                                    </div>
                                </Zoom>
                            );
                        })}
                    </div>
                    {(adminUserOfChatId.includes(auth.currentUser.uid)) ?
                        <div className="addTask">
                            <form onSubmit={addTask}>
                                <input className="input" placeholder="Add Task..." value={task} onChange={(e)=>{setTask(e.target.value)}} type="text"/>
                                <button className="submit" type="submit">
                                    <SendRoundedIcon />
                                </button>
                            </form>
                        </div>
                        :
                        <div />
                    }
                </div>
            </div>
            : <div></div>
            }




            
            
        
        </div>
  );
}

export default Chat;
