import React from 'react';
import firebase from "firebase/compat/app";
import {auth, db} from "../firebase";
import {Link, useHistory } from "react-router-dom";
import "./home.css";
import {useAuthState} from "react-firebase-hooks/auth";

function Home() {
    let history = useHistory();
    firebase.auth().onAuthStateChanged(function(user) {
        if(user === null)
        {
        history.push('/');
        }else{
            history.push('/chat');
        }
    });
    const possiblePatternBottom = [
        "M0,64L48,85.3C96,107,192,149,288,165.3C384,181,480,171,576,149.3C672,128,768,96,864,74.7C960,53,1056,43,1152,58.7C1248,75,1344,117,1392,138.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
        "M0,160L48,138.7C96,117,192,75,288,90.7C384,107,480,181,576,202.7C672,224,768,192,864,165.3C960,139,1056,117,1152,128C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
        "M0,192L48,165.3C96,139,192,85,288,69.3C384,53,480,75,576,106.7C672,139,768,181,864,197.3C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
        "M0,128L48,144C96,160,192,192,288,181.3C384,171,480,117,576,80C672,43,768,21,864,58.7C960,96,1056,192,1152,245.3C1248,299,1344,309,1392,314.7L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
        "M0,64L26.7,90.7C53.3,117,107,171,160,202.7C213.3,235,267,245,320,256C373.3,267,427,277,480,250.7C533.3,224,587,160,640,133.3C693.3,107,747,117,800,128C853.3,139,907,149,960,149.3C1013.3,149,1067,139,1120,138.7C1173.3,139,1227,149,1280,144C1333.3,139,1387,117,1413,106.7L1440,96L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
    ]
    async function loginWithGoogle()
    {
        const provider = new firebase.auth.GoogleAuthProvider();
        await auth.signInWithPopup(provider).then((result)=>{

            const document = db.collection('users').doc(result.user.uid);
            document.get()
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                document.onSnapshot((doc) => {
                    if(doc.data().fName !== "" && doc.data().lName !== "")
                    {
                        history.push("/chat");
                    }else{
                        history.push("/setup");
                    }
                });
                } else {
                    const userInformation={
                        uid: result.user.uid,
                        fName: "",
                        lName: "",
                        imgUrl: result.user.photoURL,
                        mail: result.user.email,
                        chats: []
                    }
                    document.set(userInformation, {merge:true}); // create the document
                    history.push("/setup");
                }
            });
        }).catch((error)=>{
            
        });
    }
    return (
        <div className="landingPage">
            <div className="topWaves">
                {possiblePatternBottom.map((dVal)=>{
                    return(
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={Math.floor(Math.random() * (0.6 - 0.2 + 0.6)) + 0.2} d={dVal}></path></svg>
                    );
                })};   
            </div>



            <div className="childDiv">
                <div className="subChildDiv">
                    <div className="title">
                        <h1 className="quickName">Quick</h1>
                        <h1 className="chatName">Chat</h1>
                    </div>
                    <div className="desc">
                        <p>
                            Quick Chat is a platform to connect teachers, students and friends in a quick and easy manner. ting and typesetting industry. Lorem Ipsum has been the industry's standaIpsum. To get started click the button below.
                        </p>
                        <div className="btnContainer">
                            <button className="login-with-google-btn" onClick={loginWithGoogle}>Sign In With Google</button>
                            <button className="buttonContact" variant="outlined" color="secondary">
                                
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bottomWaves">
                {possiblePatternBottom.map((dVal)=>{
                    return(
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={Math.floor(Math.random() * (0.6 - 0.2 + 0.6)) + 0.2} d={dVal}></path></svg>
                    );
                })};                
            </div>
        </div>
    );
}



export default Home;