import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {auth, db} from "../firebase";
import "./createJoinRoom.css";
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth"
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
function CjRoom() {
    let location = useLocation();
    let history = useHistory();
    const [user] = useAuthState(auth);
    db.collection("users").doc(auth.currentUser.uid).get().then((doc)=> {
        if(doc.data().fName === "" || doc.data().lName === ""){
            history.push('/');
        }
    });
    if(user === null){
        history.push("/");
    }
    const [fName, setFname] = useState("");
    const [lName, setLname] = useState("");
    const [createChatName, setCreateChatName] = useState("");
    const [joinChatName, setJoinChatName] = useState("");

    toast.configure();



    db.collection("users").doc(auth.currentUser.uid).get().then((doc)=> {
        setFname(doc.data().fName);
        setLname(doc.data().lName);
    });
    function createChat(e){
        e.preventDefault();
        if(createChatName.length <= 2){
            toast.warning('Chat Name must be more than 2 letters long.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            if(createChatName.length > 15){
                toast.warning('Chat Name must be less than 16 letters long.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                db.collection("chats").add({
                    ChatName: createChatName,
                    admin: [auth.currentUser.uid],
                    profanity: false,
                    members: [auth.currentUser.uid],
                    adminInviteLink: '',
                    memberInviteLink: ''
                }).then(()=>{
                    setCreateChatName("");
                    toast.success('Success', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch(()=>{
                    toast.error('Error, please try again later', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                })
            }
        }   
    }
    async function joinChat(e){
        e.preventDefault();
        let running = 0
        let numDoc = 0 
        db.collection("chats").get().then(snap => {numDoc = snap.size});
        db.collection("chats").onSnapshot((snapshot)=>{
            snapshot.docs.map((doc)=>{
                if(running < numDoc){
                    // CURENTLY ONLY CHECK DOC 1 NOT REST AS WE MAKE RUNNING
                    if(doc.data().adminInviteLink === joinChatName){
                        let members = []
                        let admin = []
                        for(let i=0; i<doc.data().members.length; i++){
                            members.push(
                                doc.data().members[i]
                            ) 
                        }
                        for(let i=0; i<doc.data().admin.length; i++){
                            admin.push(
                                doc.data().admin[i]
                            ) 
                        }
                        // CHECK IF USER EXISTING
                        if(!admin.includes(auth.currentUser.uid) && !members.includes(auth.currentUser.uid)){
                            members.push(auth.currentUser.uid)
                            admin.push(auth.currentUser.uid) 
                            toast.success('Successfully Added Chat', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }else if(!admin.includes(auth.currentUser.uid)){
                            admin.push(auth.currentUser.uid) 
                            toast.success('Successfully Added Chat', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        else{
                            toast.error('Already in Chat', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        db.collection("chats").doc(doc.id)
                        .set({
                            admin: admin,
                            members: members
                        }, {merge:true});
                
                        db.collection("chats").doc(doc.id)
                            .set({
                                admin: admin,
                                members: members
                        }, {merge:true});

                        running++;
                    }
                    else if(doc.data().memberInviteLink === joinChatName){
                        let members = []
                        let admin = []
                        for(let i=0; i<doc.data().members.length; i++){
                            members.push(
                                doc.data().members[i]
                            ) 
                        }
                        for(let i=0; i<doc.data().admin.length; i++){
                            admin.push(
                                doc.data().admin[i]
                            ) 
                        }
                        if(!members.includes(auth.currentUser.uid)){
                            members.push(auth.currentUser.uid)
                            toast.success('Successfully Added Chat', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }else{
                            toast.error('Already in Chat', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        db.collection("chats").doc(doc.id)
                        .set({
                            admin: admin,
                            members: members
                        }, {merge:true});

                        running++;
                    }
                    else{
                        running++;
                    }
                }
            })
        })
        
        
    }

    return (
        <div className="cjRoom-page">
            <div className="container">
                <h1>Hello, {fName} {lName}.</h1>
                <div className="joinRoom">
                    <h2>Join A Room:</h2>
                    <form>
                        <TextField className="txf" value={joinChatName} required id="standard-basic" label="Invite Link" onChange={(e)=>{setJoinChatName(e.target.value)}} />
                        <button className="submit" type="submit" onClick={(e)=>joinChat(e)}>
                            {/* <SaveIcon /> */}
                            <h2>Join Room</h2>
                        </button>
                    </form>
                </div>


                <div className="createRoom">
                    <h2>Create A Room:</h2>
                    <form>
                        <TextField className="txf" value={createChatName} required id="standard-basic" label="Chat Name" onChange={(e)=>{setCreateChatName(e.target.value)}} />
                        <button className="submit" type="submit" onClick={(e)=>createChat(e)}>
                            <h2>Create Room</h2>
                        </button>
                    </form>
                </div>
                <div className="backBtn" onClick={()=>{history.push("/chat")}}> 
                    <h2>Back</h2>
                </div>
            </div>
        </div>
    );
}







export default CjRoom;
