import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {auth, db} from "../firebase";
import firebase from "firebase/compat/app";
import "./Settings.css"
import {useHistory} from "react-router-dom";


function Settings() {
    let history = useHistory();
    const [fName, setFname] = useState('___');
    const [lName, setLname] = useState('___');
    const [warning, setWarning] = useState('');
    useEffect(()=>{
        if(fName === ""){
            setFname("___");
        }
        if(lName === ""){
            setLname("___");
        }
    });
    function signOutOfAccount()
    {
        auth.signOut();
    }
    async function saveInformation(e)
    {
        e.preventDefault();
        if(fName.length > 1){
            setWarning("");
            if(fName.length < 8){
                setWarning("");
                if(lName.length > 1){
                    setWarning("");
                    const documentUid = firebase.auth().currentUser.uid;
                    await db.collection('users').doc(documentUid).update({fName: fName, lName: lName});
                    db.collection("users").doc(firebase.auth().currentUser.uid).get().then((doc)=> {
                        if(doc.data().fName !== "" && doc.data().lName !== ""){
                            window.top.location.reload();
                        }
                    });
                }else{
                    setWarning("Last name must be longer than 2 characters.")
                }
            }else{
                setWarning("First name cannot be longer than 8 characters. You can use a nickname.")
            }
        }else{
            setWarning("First name must be longer than 2 characters.")
        }
    }
  return (
    <div className="settings-page">
        <div className="container">
            <h1>Settings Page</h1>
            <h2>Hello, {fName} {lName}.</h2>
            <form onSubmit={saveInformation}>
                <TextField className="txf" required id="standard-basic" label="First Name" onChange={(e)=>{setFname(e.target.value)}} />
                <TextField className="txf" required id="standard-basic" label="Last Name" onChange={(e)=>{setLname(e.target.value)}} />
                <h2 className="warning">{warning}</h2>
                <button className="submit" type="submit" onClick={(e)=>saveInformation(e)}>
                    <SaveIcon />
                    <h2>Save</h2>
                </button>
            </form>
            <div className="signOut" onClick={signOutOfAccount}>
                <ExitToAppIcon style={{fontSize:30}} />
                <h2 className="importantTitle s-leftPadding">Sign Out</h2>
            </div>
        </div>
    </div>
  );
}


function Setup() {
    let history=useHistory();
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [warning, setWarning] = useState('');
    function signOutOfAccount()
    {
        auth.signOut();
    }
    async function saveInformation(e)
    {
        e.preventDefault();
        if(fName.length > 1){
            setWarning("");
            if(fName.length < 8){
                setWarning("");
                
                if(lName.length > 1){
                    setWarning("");
                    const documentUid = firebase.auth().currentUser.uid;
                    await db.collection('users').doc(documentUid).update({fName: fName, lName: lName});
                    db.collection("users").doc(firebase.auth().currentUser.uid).get().then((doc)=> {
                        if(doc.data().fName !== "" && doc.data().lName !== ""){
                            history.push('/chat');
                        }
                    });
                }else{
                    setWarning("Last name must be longer than 2 characters.")
                }
            }else{
                setWarning("First name cannot be longer than 8 characters. You can use a nickname.")
            }
        }else{
            setWarning("First name must be longer than 2 characters.")
        }
    }
  return (
    <div className="settings-page">
        <div className="container">
            <h1>Settings Page</h1>
            <h2>Hello, {(fName === '') ? "____" : fName} {(lName === '') ? "____" : lName}.</h2>
            <form onSubmit={saveInformation}>
                <TextField className="txf" required id="standard-basic" label="First Name" onChange={(e)=>{setFname(e.target.value)}} />
                <TextField className="txf" required id="standard-basic" label="Last Name" onChange={(e)=>{setLname(e.target.value)}} />
                <h2 className="warning">{warning}</h2>
                <button className="submit" type="submit" onClick={(e)=>saveInformation(e)}>
                    <SaveIcon />
                    <h2>Save</h2>
                </button>
            </form>
            <div className="signOut" onClick={signOutOfAccount}>
                <ExitToAppIcon style={{fontSize:30}} />
                <h2 className="importantTitle s-leftPadding">Sign Out</h2>
            </div>
        </div>
    </div>
  );
}
















export {Settings, Setup};
